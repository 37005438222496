import React from 'react';
import { NavLink } from 'react-router-dom';

import { contacts } from '../../static/content/contacts';
import { footer as content } from '../../static/content/footer';
import './footer.scss';

export const Footer = () => (
  <footer className="footer">
    <div className="container">
      <Description />
      <Navigation />
      <Contacts />
    </div>
    <Underline />
  </footer>
);

const Description = () => (
  <div className="footer__description">
    <NavLink to="/" className="footer__description--logo">
      <img src={content.logo} alt="site-logo" />
    </NavLink>
    <div className="footer__description--text">
      <p>{content.description.text}</p>
    </div>
    <div className="social-networks">
      <a href={content.description.facebook} target="_blank" rel="noReferrer">
        <img src="/assets/images/icons/facebook-white.svg" alt="facebook icon" />
      </a>
      <a href={content.description.yelp} target="_blank" rel="noReferrer">
        <img src="/assets/images/icons/yelp-white.svg" alt="yelp icon" />
      </a>
      <a href={content.description.instagram} target="_blank" rel="noReferrer">
        <img src="/assets/images/icons/instagram-white.svg" alt="instagram icon" />
      </a>
    </div>
  </div>
);

const Navigation = () => (
  <div className="footer__navigation">
    {content.navigation.map((block, index) => (
      <div className="footer__navigation--block" key={index}>
        <h4>{block.title}</h4>
        <ul>
          {block.links.map((item, idx) => (
            <li key={idx}>
              <NavLink to={item.link}>{item.title}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);

const Contacts = () => (
  <div className="footer__contacts">
    <h4>Contact</h4>

    <div className="footer__contacts--block">
      <i className="icon-location" />
      <span>{contacts.address}</span>
    </div>

    <div className="footer__contacts--block">
      <i className="icon-phone" />
      <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
    </div>

    <div className="footer__contacts--block">
      <i className="icon-mail" />
      <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
    </div>
  </div>
);

const Underline = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer__underline">
      <div className="container">
        <span>{content.underline(currentYear)}</span>
        <a href="https://toporin.com" target="_blank" rel="noReferrer">
          Designed by <strong>Toporin Studio®</strong> in California
        </a>
      </div>
    </div>
  );
};
