import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Spinner } from '../components';

import { PageTitle, TwoColumnsGrid, CallUs, Categories } from '../sections';

const Blog = () => {
  const { isLoading, data, error } = useFetch(links.all_posts);
  const [posts, setPosts] = React.useState([]);
  const [activeCategory, setCategory] = React.useState('All');

  const categories = useFetch(links.posts_categories);

  const getPostsByCategory = async (slug) => {
    if (slug === 'All') {
      const data = await fetch(links.all_posts);

      if (data.ok) {
        data.json().then((res) => {
          setPosts(res);
        });
      }
    } else {
      const data = await fetch(links.posts_by_category(slug));

      if (data.ok) {
        data.json().then((res) => {
          setPosts(res);
        });
      }
    }
  };

  React.useEffect(() => {
    getPostsByCategory(activeCategory);
  }, [activeCategory]);

  React.useEffect(() => {
    setPosts(data);
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  console.log(categories);

  return (
    <Layout page="blog-page">
      {/* <Seo title="Blog Page" metaDescription="This is the blog page" /> */}
      <PageTitle title="Blog" />
      {!categories.error && <Categories data={categories?.data} active={activeCategory} setCategory={setCategory} />}
      <TwoColumnsGrid grid={posts} parent="blog" />
      <CallUs title="Get a Free Estimate Today!" button="Get Started" isFullWidth />
    </Layout>
  );
};

export default Blog;
