import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';

import {
  PageTitle,
  CallUs,
  PostContent,
  //  ArticlesCarousel
} from '../sections';

const Post = () => {
  const { slug } = useParams();
  const { isLoading, data, error } = useFetch(links.single_post(slug));

  const [post, setPost] = React.useState();
  const [categories, setCategories] = React.useState();
  const [pageSeoData, setPageSeoData] = React.useState();

  const articles = useFetch(links.all_posts);

  const getCategories = async (categories) => {
    const data = await fetch(links.posts_categories);

    if (data.ok) {
      data.json().then((result) => {
        let list = [];

        categories.forEach((el) => {
          result.find((category) => {
            if (category.id === el)
              list.push({
                id: category.id,
                name: category.name,
              });
            return false;
          });
        });

        setCategories(list);
      });
    }
  };

  React.useEffect(() => {
    if (data) {
      setPost(...data);
      getCategories(data[0].categories);
      setPageSeoData(data[0].yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="post-page">
      <Seo data={pageSeoData} />
      <PageTitle title="Blog" />
      <PostContent
        title={post?.title?.rendered}
        image={post?.acf?.heading_image}
        content={post?.content?.rendered}
        categories={categories}
        date={post?.date}
        latest={!articles?.error && articles.data}
        id={post?.id}
      />
      {/* {!articles?.error && <ArticlesCarousel data={articles?.data} title="You might also like" />} */}

      <CallUs title="Get a Free Estimate Today!" button="Get Started" isFullWidth />
    </Layout>
  );
};

export default Post;
