import React from 'react';
import { SectionTitle } from '../../components';

import './pageAbout.scss';

export const PageAbout = ({ title, content }) => (
  <section className="page-about">
    <div className="container">
      <SectionTitle {...{ title }} />

      {/* <div className="page-about__content">
        {content?.map((item, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </div> */}
    </div>
  </section>
);
