import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';

import { PageTitle, ContactUs } from '../sections';

const Contact = () => {
  const { isLoading, data, error } = useFetch(links.contact_page);
  const [pageData, setPageData] = React.useState();
  const [pageSeoData, setPageSeoData] = React.useState();

  React.useEffect(() => {
    if (data) {
      setPageData(data[0].acf);
      setPageSeoData(data[0].yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="contact-page">
      <Seo data={pageSeoData} />
      <PageTitle title="Contact Us" />
      <ContactUs data={pageData} />

      <div className="map" dangerouslySetInnerHTML={{ __html: pageData?.map }} />
    </Layout>
  );
};

export default Contact;
