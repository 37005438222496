import React from 'react';
import { NavLink } from 'react-router-dom';
import { SectionTitle } from '../../components';

import { contacts } from '../../static/content/contacts';

import './callUs.scss';

export const CallUs = ({ title, button, isFullWidth }) => (
  <section className={`call-us ${isFullWidth ? 'full-width' : 'with-border'}`}>
    <div className="container">
      <SectionTitle title={title} />

      <div className="call-us__buttons">
        <NavLink to="/book" className="red-button">
          {button ? button : 'Schedule my service today'}
        </NavLink>
        <span>
          or call us <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </span>
      </div>
    </div>
  </section>
);
