import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { SectionTitle } from '../../components';

import './postContent.scss';

export const PostContent = ({ title, content, image, categories, date, latest, id }) => {
  return (
    <section className="post-content">
      <div className="container">
        <div className="post-content__post">
          <SectionTitle title={title} />

          <div className="image-with-border">
            <img src={image} alt={title} />
          </div>

          <div className="post-content__post--meta">
            <span className="post-date">Date: {moment(date).format('MM/DD/YY')}</span>
            <span className="post-categories">
              Categories:{' '}
              {categories?.map((item) => (
                <span key={item.id}>{item.name}</span>
              ))}
            </span>
          </div>

          <div className="post-content__post--text" dangerouslySetInnerHTML={{ __html: content }} />
        </div>

        <div className="post-content__latest">
          <div className="post-content__latest--title">Latest News</div>

          <ul>
            {latest
              ?.filter((el) => el.id !== id)
              .map((item) => (
                <li key={item.id}>
                  <NavLink to={`/blog/${item.slug}`}>{item.title.rendered}</NavLink>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
