import React from 'react';
import Slider from 'react-slick';
import { SectionTitle } from '../../components';

import { sliderSettings } from '../../static/sliderSettings';

import './reviews.scss';

export const Reviews = ({ data }) => {
  return (
    <section className="reviews">
      <div className="container">
        <SectionTitle name={data?.section_name} title={data?.section_title} />

        <Slider className="reviews__list" {...sliderSettings}>
          {data?.testimonials.map((item, index) => (
            <ReviewCard {...{ review: item, key: index }} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.resource) {
    case 'Facebook':
      icon = '/assets/images/icons/facebook.svg';
      break;
    case 'Yelp':
      icon = '/assets/images/icons/yelp.svg';
      break;
    default:
      icon = '/assets/images/icons/google.svg';
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <div className="reviews__list--card__logo">
          <img src={icon} alt={review.logo} />
        </div>
        <div className="reviews__list--card__date">
          <div>
            <i className="icon-star" />
            <i className="icon-star" />
            <i className="icon-star" />
            <i className="icon-star" />
            <i className="icon-star" />
          </div>
          <span>{review.date}</span>
        </div>
      </div>
      <h5>
        {review.customer_name}, {review.city}
      </h5>
      <p>{review.text}</p>
    </div>
  );
};
