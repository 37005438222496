import React from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

import { banners } from '../../static/content/banners';

import './heroWithForm.scss';

export const HeroWithForm = ({ title, background }) => {
  const bgImage =
    background || 'http://admin.rmachine.org/wp-content/uploads/2022/07/sidekix-media-Ae-KSk1yM_A-unsplash.png';

  return (
    <section className="hero-with-form" style={{ '--bg-image': `url(${bgImage})` }}>
      <div className="container">
        <div className="hero-with-form__content">
          <div className="hero-with-form__content--mobile_img">
            <img src={bgImage} alt="mobile only img" />
          </div>

          <span className="hero-with-form__content--mobile_title">You Deserve</span>
          <h1 className="hero-with-form__content--title">{title}</h1>
          <p className="hero-with-form__content--subtitle">in San Francisco Bay Area</p>
          <div className="hero-with-form__content--banners">
            {banners.map((banner, index) => (
              <Banner {...{ banner, key: index }} />
            ))}
          </div>
        </div>

        <FormWrapper />
      </div>
    </section>
  );
};

const Banner = ({ banner }) => (
  <div className="rating-banner">
    <div className="rating-banner__logo">
      <img src={banner.logo} alt={banner.title} />
    </div>
    <div className="rating-banner__title">
      <span>
        <strong>{banner.title.split(' ')[0]}</strong> {banner.title.split(' ')[1]}
      </span>
      <div className="rating-banner__stars">
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
        <i className="icon-star" />
      </div>
    </div>
  </div>
);

const FormWrapper = () => {
  const form = React.useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kwjy0no', 'template_qdnroox', form.current, '_H7KZOIMQlCyl_CKF').then(
      (result) => {
        if (result.text === 'OK') {
          toast.success('Message Sent Successfully');
          navigate('/thank-you');
        }
      },
      (error) => {
        toast.error('Ooops, something went wrong! Try again!');
      },
    );
    e.target.reset();
  };

  return (
    <div className="hero-with-form__feedback-form">
      <h3>Schedule an appointment</h3>

      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" required placeholder="Full Name *" />
        <input type="email" name="user_email" required placeholder="Email *" />
        <input type="number" name="user_phone" required placeholder="Phone *" />
        <textarea name="message" required placeholder="Please let us know if you have any special requests *" />
        <div className="data-protection">
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
          >
            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
          </svg>

          <p>Data protected and privacy</p>
        </div>
        <input type="submit" className="red-button" value="Get a Quote" />
      </form>
    </div>
  );
};
