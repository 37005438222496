import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';

import { PageTitle, FAQ, Categories, GetInTouch } from '../sections';

const categories = [
  {
    id: 1,
    name: 'Flooring FAQ’s',
  },
  {
    id: 2,
    name: 'Stairs FAQ’s',
  },
];

const FAQPage = () => {
  const { isLoading, data, error } = useFetch(links.faq_page);
  const [pageData, setPageData] = React.useState();
  const [activeCategory, setCategory] = React.useState('All');
  const [pageSeoData, setPageSeoData] = React.useState();

  React.useEffect(() => {
    if (data) {
      switch (activeCategory) {
        case 1:
          setPageData(data[0].acf?.flooring_faq);
          break;
        case 2:
          setPageData(data[0].acf?.stairs_faq);
          break;
        case 'All':
          setPageData([...data[0].acf.flooring_faq, ...data[0].acf.stairs_faq]);
          break;
        default:
          break;
      }
    }
  }, [activeCategory, data]);

  React.useEffect(() => {
    if (data) {
      const arr = [...data[0].acf.flooring_faq, ...data[0].acf.stairs_faq];
      setPageData(arr);
      setPageSeoData(data[0].yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="faq-page">
      <Seo data={pageSeoData} />
      <PageTitle title="FAQ" />
      <Categories data={categories} active={activeCategory} setCategory={setCategory} />
      <FAQ data={pageData} />
      <GetInTouch title="Ask Your Question" subtitle="Use the form to contact with our specialists." />
    </Layout>
  );
};

export default FAQPage;
