import React from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import moment from 'moment';
import { SectionTitle } from '../../components';

import { sliderSettings } from '../../static/sliderSettings';

import './articlesCarousel.scss';

export const ArticlesCarousel = ({ data, title, name }) => (
  <section className="articles">
    <div className="container">
      <SectionTitle name={name} title={title} />

      <Slider className="articles__list" {...sliderSettings}>
        {data?.map((post, index) => (
          <ArticleCard {...{ article: post, key: index }} />
        ))}
      </Slider>
    </div>
  </section>
);

const ArticleCard = ({ article }) => (
  <div className="articles__list--card">
    <div className="image-with-border">
      <img src={article.acf.heading_image} alt={article.title.rendered} />
    </div>
    <span>{moment(article.date).format('MM/DD/YY')}</span>
    <h4>{article.title.rendered}</h4>
    <NavLink to={`/blog/${article.slug}`} className="red-button inverse">
      Read More
    </NavLink>
  </div>
);
