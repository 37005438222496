import React from 'react';

import './contactUs.scss';

import { GetInTouch } from '..';
// import { SectionTitle } from '../../components';

export const ContactUs = ({ data }) => (
  <section className="contact-us">
    <div className="content">
      {/* <SectionTitle title="Red Machine Flooring & Stairs Services, San Francisco, CA" /> */}

      {/* <p>{data?.content}</p> */}

      <div className="contact-us__data">
        <div className="contact-us__data--item">
          <i className="icon-location" />
          <div>
            <p className="city">{data?.address.city}</p>
            <span>{data?.address.street}</span>
          </div>
        </div>

        <div className="contact-us__data--item">
          <i className="icon-phone" />
          <div>
            <a href={`tel:${data?.phone}`}>{data?.phone}</a>
          </div>
        </div>

        <div className="contact-us__data--item">
          <i className="icon-mail" />
          <div>
            <a href={`mailto:${data?.email}`}>{data?.email}</a>
          </div>
        </div>

        <div className="contact-us__data--item">
          <i className="icon-clock" />
          <div>
            <p>Working Hours:</p>
            <span>{data?.working_hours}</span>
          </div>
        </div>
      </div>
    </div>

    <GetInTouch title="Get In Touch" subtitle="We would love to hear from you! Please fill out the contact form." />
  </section>
);
