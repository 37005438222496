import React from 'react';
import { SectionTitle } from '../../components';

import './satisfaction.scss';

export const Satisfaction = ({ data }) => (
  <section className="satisfaction-guaranteed">
    <div className="container">
      <SectionTitle name={data.section_name} title={data.section_title} />
      <div className="links">
        <div className="links__wrapper">
          <div>
            <img src="/assets/images/logos/google-full.svg" alt="google" />
            <Stars />
          </div>

          {/* <div>320 reviews</div> */}
        </div>

        <a href="https://www.facebook.com/rmachine.org" target="_blank" rel="noReferrer" className="links__wrapper">
          <div>
            <img src="/assets/images/logos/facebook-full.svg" alt="facebook" />
            <Stars />
          </div>

          {/* <div>180 reviews</div> */}
        </a>

        <a
          href="https://www.yelp.com/biz/red-machine-flooring-san-jose-3"
          target="_blank"
          rel="noReferrer"
          className="links__wrapper"
        >
          <div>
            <img src="/assets/images/logos/yelp-full.svg" alt="yelp" />
            <Stars />
          </div>

          {/* <div>350 reviews</div> */}
        </a>
      </div>
    </div>
  </section>
);

const Stars = () => (
  <div className="stars">
    <i className="icon-star"></i>
    <i className="icon-star"></i>
    <i className="icon-star"></i>
    <i className="icon-star"></i>
    <i className="icon-star"></i>
  </div>
);
