import React from 'react';
import { NavLink } from 'react-router-dom';
import { SectionTitle } from '../../components';

import './whoWeAre.scss';

export const WhoWeAre = ({ data }) => (
  <section className="who-we-are">
    <div className="container">
      <div className="who-we-are__content">
        <SectionTitle name={data?.section_name} title={data?.section_title} />
        <h3>{data?.section_subtitle}</h3>

        <div className="who-we-are__content--text" dangerouslySetInnerHTML={{ __html: data?.content }} />

        <NavLink to="/book" className="red-button">
          {data?.button_text}
        </NavLink>
      </div>
      <div className="image-with-border">
        <img src={data?.section_image} alt="Who We Are" />
      </div>
    </div>
  </section>
);
