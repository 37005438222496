import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';

import { PageTitle, CallUs } from '../sections';

const Area = () => {
  const { slug } = useParams();
  const { isLoading, data, error } = useFetch(links.single_area(slug));
  const [pageData, setPageData] = React.useState();
  const [pageSeoData, setPageSeoData] = React.useState();

  React.useEffect(() => {
    if (data) {
      setPageData(...data);
      setPageSeoData(data[0].yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="area-page">
      <Seo data={pageSeoData} />
      <PageTitle title={pageData?.title.rendered} />

      <section className="area-content">
        <div className="container" dangerouslySetInnerHTML={{ __html: pageData?.content.rendered }} />
        <div className="area-content__map" dangerouslySetInnerHTML={{ __html: pageData?.acf.map }} />
      </section>

      <CallUs title="Get a Free Estimate Today!" button="Get Started" isFullWidth />
    </Layout>
  );
};

export default Area;
