import React from 'react';

import './sectionTitle.scss';

export const SectionTitle = ({ name, nameColor, title, titleColor }) => {
  return (
    <div className="section-title">
      {name && (
        <span className="section-title__name" style={{ color: nameColor || '#C9BDBD' }}>
          {name}
        </span>
      )}
      <h2 className="section-title__heading" style={{ color: titleColor || '#310003' }}>
        {title}
      </h2>
    </div>
  );
};
