import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';
import {
  PageTitle,
  PageAbout,
  // Counter,
  Superiority,
  Areas,
  Satisfaction,
  CallUs,
  InfoBlock,
  // OurTeam,
} from '../sections';

const About = () => {
  const { isLoading, data, error } = useFetch(links.about_page);
  const [pageData, setPageData] = React.useState([]);
  const [pageSeoData, setPageSeoData] = React.useState();

  React.useEffect(() => {
    if (data) {
      setPageData({ ...data[0] });
      setPageSeoData(data[0].yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="about-page">
      <Seo data={pageSeoData} />
      <PageTitle title={pageData?.acf?.page_title} />
      <PageAbout
        title={pageData?.acf?.section_title}
        content={[pageData?.acf?.left_column, pageData?.acf?.right_column]}
      />
      {/* <Counter /> */}
      <Superiority />
      <InfoBlock data={pageData?.acf?.info_block} />
      {/* <OurTeam data={pageData?.acf?.team_list} /> */}
      <Areas />
      <Satisfaction
        data={{
          section_title: '100% Satisfaction Guaranteed',
        }}
      />
      <CallUs title="Get a Free Estimate Today!" button="Get Started" isFullWidth />
    </Layout>
  );
};

export default About;
