export const banners = [
  {
    title: 'Google Rating',
    url: 'https://www.google.com/',
    logo: 'http://admin.rmachine.org/wp-content/uploads/2022/07/google.svg',
  },
  {
    title: 'BBB Rating',
    // url: 'https://www.google.com/',
    logo: 'http://admin.rmachine.org/wp-content/uploads/2022/07/bbb.svg',
  },
  {
    title: 'Yelp Rating',
    url: 'https://www.yelp.com/biz/red-machine-flooring-san-jose-3',
    logo: 'http://admin.rmachine.org/wp-content/uploads/2022/07/yelp.svg',
  },
  {
    title: 'HomeAdvisor Rating',
    // url: 'https://www.google.com/',
    logo: 'http://admin.rmachine.org/wp-content/uploads/2022/07/home-advisor.svg',
  },
];
