import React from 'react';
import { NavLink } from 'react-router-dom';
import { SectionTitle } from '../../components';

import './twoColumnsGrid.scss';

export const TwoColumnsGrid = ({ data, grid, parent }) => (
  <section className="two-columns-grid">
    <div className="container">
      {data?.section_title && <SectionTitle {...{ title: data?.section_title, name: data?.section_name }} />}
      <div className="two-columns-grid__item">
        {grid?.map((item) => (
          <Card {...{ item, key: item.id, parent }} />
        ))}
      </div>
    </div>
  </section>
);

const Card = ({ item, parent }) => (
  <NavLink to={`/${parent}/${item?.slug}`} className="grid-card">
    <div className="image-with-border">
      <img src={item?.acf.heading_image} alt={item?.title.rendered} />
    </div>
    <div className="grid-card__content">
      <h4>{item?.title.rendered}</h4>
      <span className="red-button inverse">Learn More</span>
    </div>
  </NavLink>
);
