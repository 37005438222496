import React from 'react';

import { SectionTitle, Accordion } from '../../components';

import './faq.scss';

export const FAQ = ({ data }) => (
  <section className="faq">
    <div className="container">
      <SectionTitle name="Questions" title="Frequently Asked Questions" />

      <div className="faq__list">
        {data?.map((item, index) => (
          <Accordion key={index} data={item} />
        ))}
      </div>
    </div>
  </section>
);
