import React from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

import './bookingForm.scss';

export const BookingForm = () => {
  const form = React.useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kwjy0no', 'template_gch49lg', form.current, '_H7KZOIMQlCyl_CKF').then(
      (result) => {
        if (result.text === 'OK') {
          toast.success('Message Sent Successfully');
          navigate('/thank-you');
        }
      },
      (error) => {
        toast.error('Ooops, something went wrong! Try again!');
      },
    );
    e.target.reset();
  };

  return (
    <div className="booking-form">
      <h2>Book Now</h2>
      <form ref={form} onSubmit={sendEmail}>
        <select name="service" required>
          <option hidden>Select Service</option>
          <option value="Subfloor Preparation / Leveling">Subfloor Preparation / Leveling</option>
          <option value="Engineered Wood Flooring">Engineered Wood Flooring</option>
          <option value="Solid Wood Flooring">Solid Wood Flooring</option>
          <option value="Vinyl and Laminate Flooring">Vinyl and Laminate Flooring</option>
          <option value="Hardwood Floor Refinishing">Hardwood Floor Refinishing</option>
          <option value="Soundproof Flooring">Soundproof Flooring</option>
          <option value="Floor Demolition">Floor Demolition</option>
          <option value="Handrail Installation">Handrail Installation</option>
          <option value="Staining/Finishing Handrails">Staining/Finishing Handrails</option>
          <option value="Stairs Installation">Stairs Installation</option>
          <option value="Custom Staircase Installation">Custom Staircase Installation</option>
        </select>
        <input type="text" required name="name" placeholder="Full Name *" />
        <input type="text" required name="email" placeholder="Email *" />
        <input type="number" required name="phone" placeholder="Phone *" />
        <textarea name="message" required placeholder="Please let us know if you have any special requests *" />
        <div className="data-protection">
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
          >
            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
          </svg>

          <p>Data protected and privacy</p>
        </div>
        <input type="submit" className="red-button" />
      </form>
    </div>
  );
};
