const site_url = 'https://admin.rmachine.org/wp-json/wp/v2';

export const links = {
  all_posts: `${site_url}/posts?per_page=100`,
  single_post: (slug) => `${site_url}/posts?slug=${slug}`,
  posts_categories: `${site_url}/categories?per_page=100`,
  posts_by_category: (slug) => `${site_url}/posts?categories=${slug}&per_page=100`,
  all_services: `${site_url}/services?per_page=100`,
  single_service: (slug) => `${site_url}/services?slug=${slug}`,
  home_page: `${site_url}/pages?slug=home-page`,
  services_page: `${site_url}/pages?slug=services-page`,
  about_page: `${site_url}/pages?slug=about-company-page`,
  contact_page: `${site_url}/pages?slug=contact-page`,
  faq_page: `${site_url}/pages?slug=faq`,
  all_areas: `${site_url}/areas?per_page=100`,
  single_area: (slug) => `${site_url}/areas?slug=${slug}`,
};
