import React from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

import { SectionTitle } from '../../components';

import './getInTouch.scss';

export const GetInTouch = ({ title, name, subtitle, button }) => {
  const form = React.useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kwjy0no', 'template_qdnroox', form.current, '_H7KZOIMQlCyl_CKF').then(
      (result) => {
        if (result.text === 'OK') {
          toast.success('Message Sent Successfully');
          navigate('/thank-you');
        }
      },
      (error) => {
        toast.error('Ooops, something went wrong! Try again!');
      },
    );
    e.target.reset();
  };

  return (
    <section className="get-in-touch">
      <div className="container">
        <SectionTitle title={title} titleColor="#fff" name={name} />

        {subtitle && <p>{subtitle}</p>}

        <form ref={form} onSubmit={sendEmail}>
          <div>
            <div>
              <input name="user_name" required type="text" placeholder="Full Name *" />
              <input name="user_phone" required type="number" placeholder="Phone *" />
              <input name="user_email" required type="email" placeholder="Email *" />
            </div>

            <div>
              <textarea name="message" required placeholder="Your Question *" />
            </div>
          </div>

          <input type="submit" value={button ? button : 'Submit'} className="red-button" />
        </form>
        <div className="data-protection">
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
          >
            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
          </svg>

          <p>Data protected and privacy</p>
        </div>
      </div>
    </section>
  );
};
