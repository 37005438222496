import React from 'react';

import './pageTitle.scss';

export const PageTitle = ({ title }) => (
  <section className="page-title">
    <div className="container">
      <h1>{title}</h1>
    </div>
  </section>
);
