import React from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';
import {
  HeroWithForm,
  Superiority,
  WhoWeAre,
  TwoColumnsGrid,
  Reviews,
  CallUs,
  // Portfolio,
  Areas,
  ContactOurSpecialists,
  ArticlesCarousel,
  CallToAction,
} from '../sections';

const Home = () => {
  const { isLoading, data, error } = useFetch(links.home_page);
  const [pageData, setPageData] = React.useState();
  const [pageSeoData, setPageSeoData] = React.useState();

  const articles = useFetch(links.all_posts);
  const services = useFetch(links.all_services);

  const [flooringServices, setFlooringServices] = React.useState();
  const [stairsServices, setStairsServices] = React.useState();

  React.useEffect(() => {
    if (services?.data) {
      const flooring = services?.data.filter((el) => el.categories[0] !== 5).sort((a, b) => a.id - b.id);
      const stairs = services?.data.filter((el) => el.categories[0] === 5).sort((a, b) => a.id - b.id);
      setFlooringServices(flooring);
      setStairsServices(stairs);
    }
  }, [services?.data]);

  React.useEffect(() => {
    if (data) {
      setPageData(data[0].acf);
      setPageSeoData(data[0].yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="home-page">
      <Seo data={pageSeoData} />
      <HeroWithForm title={pageData?.main.page_title} banners={pageData?.main.rating_links} />
      <Superiority />
      <WhoWeAre data={pageData?.who_we_are} />
      {flooringServices && <TwoColumnsGrid data={pageData?.our_services} grid={flooringServices} parent="services" />}
      {stairsServices && (
        <TwoColumnsGrid
          data={{
            section_title: 'Stairs Services We Provide',
          }}
          grid={stairsServices}
          parent="services"
        />
      )}
      <Reviews data={pageData?.reviews} />
      <CallUs title="Your Home Project is Just a Call Away" button="Get a Free Estimate" />
      {/* <Portfolio data={pageData?.portfolio} /> */}
      <Areas />
      <ContactOurSpecialists />
      {!articles?.error && <ArticlesCarousel data={articles?.data} title="Articles and News" name="Blog" />}
      <CallToAction />
    </Layout>
  );
};

export default Home;
