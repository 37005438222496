import React from 'react';

import { contacts } from '../../static/content/contacts';

import './payment.scss';

export const Payment = ({ title, button }) => (
  <section className="payment">
    <div className="container">
      <h2>{title ? title : 'Schedule Your FREE In-Home Estimate!'}</h2>
      {/* <p>
        Don't wait to get your life back in order. We've helped thousands of families over the past 20+ years and we'll
        help you too. If you need any help, please contact us right away. We will get back to you as soon as possible.
      </p> */}
      <div className="payment__link">
        <a href={`tel:${contacts.phone}`} className="red-button">
          {button ? button : contacts.phone}
        </a>
        <span>Call us now!</span>
      </div>
      <div className="payment__payment-systems">
        <div>
          <img src="/assets/images/payments/mastercard.svg" alt="mastercard" />
        </div>
        <div>
          <img src="/assets/images/payments/visa.svg" alt="visa" />
        </div>
        <div>
          <img src="/assets/images/payments/discover.svg" alt="discover" />
        </div>
        <div>
          <img src="/assets/images/payments/ae.svg" alt="american express" />
        </div>
      </div>
    </div>
  </section>
);
