import React from 'react';
import { NavLink } from 'react-router-dom';

import { contacts } from '../../static/content/contacts';
import { header as content } from '../../static/content/header';

import './header.scss';

export const Header = () => {
  const [shadow, setShadow] = React.useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 142);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`header ${shadow && 'shadow'}`}>
      <Headline />
      <Navigation />
    </header>
  );
};

const Headline = () => (
  <div className="header__headline">
    <div className="container">
      <NavLink to="/" className="header__headline--logo">
        <img src={content.logo} alt="site-logo" />
      </NavLink>

      <div className="header__headline--links">
        <a href={`tel:${contacts.phone}`} className="header__headline--phone">
          {contacts.phone}
        </a>
        <NavLink to="/book" className="header__headline--book">
          {content.book_button}
        </NavLink>
      </div>
    </div>
  </div>
);

const Navigation = () => {
  const [open, setOpen] = React.useState(false);

  const menuToggle = () => setOpen(!open);

  return (
    <div className="header__navigation">
      <div className="container">
        <Burger onClick={menuToggle} />
        <div className="header__navigation--phone">
          <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
        </div>
        <div {...{ className: `header__navigation--menu_wrapper ${open && 'open'}` }}>
          <CloseButton onClick={menuToggle} />
          <nav>
            <Menu />
          </nav>
        </div>
      </div>
    </div>
  );
};

const Burger = ({ onClick }) => (
  <div className="header__navigation--burger" {...{ onClick }}>
    <span />
    <span />
    <span />
  </div>
);

const CloseButton = ({ onClick }) => (
  <div className="header__navigation--close" {...{ onClick }}>
    <i className="icon-cancel" />
  </div>
);

const Menu = () => (
  <ul className="menu">
    {content.navigation.map((fist_level_menu, index) => (
      <li key={index} className="menu__first-level-item">
        {fist_level_menu.link ? (
          <NavLink to={fist_level_menu.link} className="menu__first-level-item__header">
            {fist_level_menu.title}
          </NavLink>
        ) : (
          <React.Fragment>
            <span className="menu__first-level-item__header">{fist_level_menu.title}</span>
            <SecondLevelMenu menu={fist_level_menu.submenu} />
          </React.Fragment>
        )}
      </li>
    ))}
  </ul>
);

const SecondLevelMenu = ({ menu }) => (
  <ul className="menu-second-level">
    {menu.map((second_level_menu, index) => (
      <li key={index} className="menu-second-level__item">
        {second_level_menu.link ? (
          <NavLink to={second_level_menu.link} className="menu-second-level__item--header">
            {second_level_menu.title}
          </NavLink>
        ) : (
          <React.Fragment>
            <span className="menu-second-level__item--header">{second_level_menu.title}</span>
            <ThirdLevelMenu menu={second_level_menu.submenu} />
          </React.Fragment>
        )}
      </li>
    ))}
  </ul>
);

const ThirdLevelMenu = ({ menu }) => (
  <ul className="menu-third-level">
    {menu.map((third_level_menu, index) => (
      <li key={index} className="menu-third-level__item">
        <NavLink to={third_level_menu.link} className="menu-third-level__item--header">
          {third_level_menu.title}
        </NavLink>
      </li>
    ))}
  </ul>
);
