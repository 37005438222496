import React from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

import { SectionTitle } from '../../components';

import './contactOurSpecialists.scss';

export const ContactOurSpecialists = () => {
  const form = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kfe74uc', 'template_nm6ihlu', form.current, 'w2We-ApbrU5p_VyW_').then(
      (result) => {
        if (result.text === 'OK') {
          toast.success('Message Sent Successfully');
        }
      },
      (error) => {
        toast.error('Ooops, something went wrong! Try again!');
      },
    );
    e.target.reset();
  };

  return (
    <section className="contact-our-specialists">
      <div className="container">
        <SectionTitle name="Get in touch" title="Contact Our Specialists" />
        <div className="contact-our-specialists__form">
          <p>Use the form to schedule an appointment or request a free estimate.</p>

          <form ref={form} onSubmit={sendEmail}>
            <input name="user_name" required type="text" placeholder="Full Name *" />
            <input name="user_phone" required type="number" placeholder="Phone *" />
            <input name="user_email" required type="email" placeholder="Email *" />
            <input name="message" required type="text" placeholder="Inquiry about *" />
            <input type="submit" value="Submit" className="red-button" />
          </form>
          <div className="data-protection">
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
            >
              <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
            </svg>

            <p>Data protected and privacy</p>
          </div>
        </div>
      </div>
    </section>
  );
};
