import React from 'react';
import { SectionTitle } from '../../components';

import './weService.scss';

export const WeService = ({ data }) => (
  <section className="we-services">
    <div className="container">
      <div className="we-services__content">
        <SectionTitle name="Red Machine Flooring" title={data?.title} />

        <div className="we-services__content--description" dangerouslySetInnerHTML={{ __html: data?.content }} />
      </div>
    </div>
  </section>
);
