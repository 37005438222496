import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import PageNotFound from './pages/404';
import Blog from './pages/Blog';
import Post from './pages/Post';
import Services from './pages/Services';
import Service from './pages/Service';
import About from './pages/About';
import FAQPage from './pages/FAQ';
import Contact from './pages/Contact';
import Area from './pages/Area';
import Booking from './pages/Booking';
import ThankYouPage from './pages/ThankYouPage';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/404" element={<PageNotFound />} />

      <Route path="/blog" element={<Blog />} />
      <Route path="blog/:slug" element={<Post />} />

      <Route path="/services" element={<Services />} />
      <Route path="/services/:slug" element={<Service />} />

      <Route path="/service-area" element={<Navigate replace to="/" />} />
      <Route path="/service-area/:slug" element={<Area />} />

      <Route path="/about" element={<About />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/book" element={<Booking />} />

      <Route path="/thank-you" element={<ThankYouPage />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  </BrowserRouter>
);

export default App;
