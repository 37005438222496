import React from 'react';

import { Layout, Seo, BookingForm } from '../components';

import { CallUs } from '../sections';

const Booking = () => {
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Layout>
      <Seo title="Booking Page" metaDescription="This is the booking page" />

      <section className="book-now">
        <div className="container">
          <BookingForm />
        </div>
      </section>

      <CallUs title="Get a Free Estimate Today!" button="Get Started" isFullWidth />
    </Layout>
  );
};

export default Booking;
