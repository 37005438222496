import React from 'react';
import { NavLink } from 'react-router-dom';
import { SectionTitle } from '../../components';

import './callToAction.scss';

export const CallToAction = () => (
  <section className="call-to-action">
    <div className="container">
      <SectionTitle title="Get a Free Estimate Today!" titleColor="#fff" />

      <NavLink to="/book" className="red-button">
        Get Started
      </NavLink>
    </div>
  </section>
);
