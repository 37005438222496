import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { links } from '../static/links';

import { Layout, Seo, Spinner } from '../components';

import {
  HeroWithForm,
  Superiority,
  CallUs,
  Areas,
  TwoColumnsGrid,
  // Counter,
  Satisfaction,
  // Portfolio,
  WeService,
  Payment,
  FAQ,
  GetInTouch,
} from '../sections';

const Service = () => {
  const { slug } = useParams();
  const { isLoading, data, error } = useFetch(links.single_service(slug));

  const services = useFetch(links.all_services);

  const [service, setService] = React.useState([]);
  const [pageSeoData, setPageSeoData] = React.useState();

  const [flooringServices, setFlooringServices] = React.useState();
  const [stairsServices, setStairsServices] = React.useState();

  React.useEffect(() => {
    if (services?.data) {
      const flooring = services?.data.filter((el) => el.categories[0] !== 5).sort((a, b) => a.id - b.id);
      const stairs = services?.data.filter((el) => el.categories[0] === 5).sort((a, b) => a.id - b.id);
      setFlooringServices(flooring);
      setStairsServices(stairs);
    }
  }, [services?.data]);

  React.useEffect(() => {
    if (data) {
      setService({ ...data[0] });
      setPageSeoData(data[0]?.yoast_head_json);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="service-page">
      <Seo data={pageSeoData} />
      <HeroWithForm title={service?.acf?.page_title} background={service?.acf?.heading_image} />
      <Superiority />
      <WeService data={service?.acf?.service_info} />
      {/* <Portfolio data={service?.acf?.portfolio} /> */}
      {/* <Counter /> */}
      <Satisfaction
        data={{
          section_name: 'Reviews',
          section_title: '100% Satisfaction Guaranteed',
        }}
      />
      <CallUs title={service?.acf?.call_to_action?.title} button={service?.acf?.call_to_action?.button} isFullWidth />
      <Areas />
      <FAQ data={service?.acf?.faq} />
      <Payment title={service?.acf?.call_us?.title} button={service?.acf?.call_us?.button} />
      {service.categories && (
        <TwoColumnsGrid
          {...{
            parent: 'services',
            data: {
              section_title: `${service?.categories[0] === 5 ? 'Stairs' : 'Flooring'} Services We Provide`,
              section_name: 'Our services',
            },
            grid:
              service?.categories[0] === 5
                ? stairsServices?.filter((el) => el.id !== service?.id)
                : flooringServices?.filter((el) => el.id !== service?.id),
          }}
        />
      )}
      <GetInTouch
        title={service?.acf?.get_in_touch?.title}
        name={service?.acf?.get_in_touch?.label}
        subtitle={service?.acf?.get_in_touch?.subtitle}
        button={service?.acf?.get_in_touch?.button}
      />
    </Layout>
  );
};

export default Service;
