export const footer = {
  logo: '/assets/images/logos/site-logo.svg',
  description: {
    text: `We're known for our attention to detail, impeccable craftsmanship, and fair pricing, and we're dedicated to providing each of our clients with a truly outstanding experience from start to finish.`,
    facebook: 'https://www.facebook.com/rmachine.org',
    yelp: 'https://www.yelp.com/biz/red-machine-flooring-san-jose-3',
    instagram: 'https://instagram.com/rmachineflooring?igshid=YmMyMTA2M2Y=',
  },
  navigation: [
    {
      title: 'Services',
      links: [
        {
          title: 'Subfloor Preparation',
          link: '/services/subfloor-preparation',
        },
        {
          title: 'Engineered Wood Flooring',
          link: '/services/engineered-wood-flooring',
        },
        {
          title: 'Solid Wood Flooring',
          link: '/services/solid-wood-flooring',
        },
        {
          title: 'Laminate Flooring',
          link: '/services/laminate-flooring',
        },

        {
          title: 'Vinyl Flooring Installation',
          link: '/services/vinyl-flooring',
        },
        {
          title: 'Hardwood Floor Refinishing',
          link: '/services/hardwood-floor-refinishing',
        },
        {
          title: 'Soundproof Flooring',
          link: '/services/soundproof-flooring',
        },
        {
          title: 'Floor Demolition',
          link: '/services/floor-demolition',
        },
        {
          title: 'Handrail Installation',
          link: '/services/handrail-installation',
        },
        {
          title: 'Handrail Refinishing',
          link: '/services/handrail-refinishing',
        },
        {
          title: 'Stairs Installation',
          link: '/services/stairs-installation',
        },
        {
          title: 'Custom Staircase Installation',
          link: '/services/custom-staircase-installation',
        },
      ],
    },
    {
      title: 'About',
      links: [
        {
          title: 'About Company',
          link: '/about',
        },
        {
          title: 'Services',
          link: '/services',
        },
        {
          title: 'Blog',
          link: '/blog',
        },
        {
          title: 'FAQ',
          link: '/faq',
        },
      ],
    },
  ],
  underline: (year) => `©${year} Red Machine. All Rights Reserved.`,
};
