import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from '../';

import { contacts } from '../../static/content/contacts';
import './layout.scss';

export const Layout = ({ page, children }) => (
  <div id={page}>
    <Header />
    <main>{children}</main>
    <Footer />
    <ToastContainer />
    <div className="mobile-cta-buttons">
      <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
      <Link to="/book">Schedule a Service</Link>
    </div>
  </div>
);
