import React from 'react';

import './categories.scss';

export const Categories = ({ data, active, setCategory }) => {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      const filteredCategories = data.filter(
        (el) => el.name !== 'Services' && el.slug !== 'stairs' && el.slug !== 'flooring' && el.slug !== 'uncategorized',
      );
      setCategories(filteredCategories);
    }
  }, [data]);

  return (
    <section className="categories">
      <div className="container">
        <div className="categories__list">
          <div className={active === 'All' ? 'active' : null} onClick={() => setCategory('All')}>
            All
          </div>
          {categories &&
            categories.map((item) => (
              <div key={item.id} className={active === item.id ? 'active' : null} onClick={() => setCategory(item.id)}>
                {item.name}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
