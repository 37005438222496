import React from 'react';

import './accordion.scss';

export const Accordion = ({ data }) => {
  const [isActive, setIsActive] = React.useState(false);

  const toggle = () => setIsActive(!isActive);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={toggle}>
        <h3>{data.question}</h3>
        <div className={`icon ${isActive && 'active'}`}>
          {isActive ? (
            <img src="/assets/images/icons/minus.svg" alt="minus" />
          ) : (
            <img src="/assets/images/icons/plus.svg" alt="plus" />
          )}
        </div>
      </div>
      {isActive && <div className="accordion-content">{data.answer}</div>}
    </div>
  );
};
