import React from 'react';
import useFetch from 'react-fetch-hook';
import { NavLink } from 'react-router-dom';

import { links } from '../../static/links';

import { SectionTitle } from '../../components';

import './areas.scss';

export const Areas = () => {
  const { data } = useFetch(links.all_areas);

  return (
    <section className="areas">
      <div className="container">
        <div>
          <SectionTitle title="Areas We Serve" titleColor="#fff" name="Where?" />
        </div>
        <div>
          <ul className="areas-list">
            {data
              ?.sort(function (a, b) {
                if (a.title.rendered < b.title.rendered) {
                  return -1;
                }
                if (a.title.rendered > b.title.rendered) {
                  return 1;
                }
                return 0;
              })
              .map((item) => (
                <li key={item.id}>
                  <NavLink to={`/service-area/${item.slug}`}>{item.title.rendered}</NavLink>
                </li>
              ))}
          </ul>
          <div className="red-label">
            <img src="/assets/images/icons/Group-953.svg" alt="label" />
          </div>
        </div>
      </div>
    </section>
  );
};
