import React from 'react';

import './superiority.scss';

const data = [
  {
    image: '/assets/images/icons/Group-961.svg',
    title: 'Only High Quality Materials',
  },
  {
    image: '/assets/images/icons/Group-962.svg',
    title: 'Prompt, Reliable Service',
  },
  {
    image: '/assets/images/icons/Group-963.svg',
    title: 'We use Zero VOC Glue',
  },
];

export const Superiority = () => (
  <section className="superiority">
    <div className="container">
      {data?.map((item, index) => (
        <div className="superior" key={index}>
          <div className="superior__image-wrapper">
            <img src={item.image} alt={item.title} />
          </div>
          <p>{item.title}</p>
        </div>
      ))}
    </div>
  </section>
);
